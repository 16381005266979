import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Test from './screens/Test';
import Test2 from './screens/Test2';

function App() {
  return (
    <div className="App">
       <BrowserRouter>
          <Routes>
            <Route path="/" element={<Test />} />
            <Route path="/test" element={<Test2 />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
